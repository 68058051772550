// Alpine.directive('[name]', (el, { value, modifiers, expression }, { Alpine, effect, cleanup }) => {})

const xOnloadShowImage = function(el, {}, { cleanup }) {

  if (el.complete) {
    !el.classList.contains('opacity-100') && el.classList.add('opacity-100');

    return;
  }

  const handler = event => {
    const el = event.target;

    !el.classList.contains('opacity-100') && el.classList.add('opacity-100');
  };

  el.addEventListener('load', handler);

  cleanup(() => {
    el.removeEventListener('load', handler);
  });
}

export default xOnloadShowImage;
