// AppEnv is automatically prepended to the bundled JS file and accessible globally
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus';
import htmx from 'htmx.org';
import intersect from '@alpinejs/intersect'
import persist from '@alpinejs/persist'
import { DateTime } from 'luxon';
import xOnloadShowImage from './alpine_directives/x_onload_show_image'
import {listen, prefetch} from 'quicklink';

/**
 * Initializes quicklink prefetching
 * Only enabled in production due to unnecessary loads on local unoptimized dev server
 */
window.addEventListener('load', e => {
  listen({
    ignores: [
      uri => uri.includes('#'), // Ignore any link with a hash/anchor
      uri => uri.includes('.pdf'),
      uri => uri.includes('.doc'),
      uri => uri.includes('docx'),
      uri => uri.includes('xls'),
      uri => uri.includes('xlsx'),
      uri => uri.includes('mp3'),
      uri => uri.includes('wav'),
      uri => uri.includes('zip'),
      uri => uri.includes('odt'),
      uri => uri.includes('odf'),
      uri => uri.includes('ods'),
      (uri, elem) => elem.hasAttribute('noprefetch'),
    ]
  });
});



Alpine.magic('dateTime', () => DateTime);

document.addEventListener('alpine:init', e => {
  Alpine.directive('showonload', xOnloadShowImage);
});

Alpine.plugin(collapse)
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(persist);

window.Alpine = Alpine;

Alpine.start();

window.htmx = htmx;
